@media only screen and (min-width: 769px) {
  :root {
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    --text-primary: #0B4141;
    --text-secondary: #7DA2A9;
    --text-third: #FF6864;
    --text-4: #fbfbfb;
    --bg-primary: #fbfbfb;
    --bg-secondary: #7DA2A9;
    --bg-third: #FF6864;
    --transition-speed: 600ms;
  }

  body::-webkit-scrollbar {
    display: none;
  }
  .container {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    display: grid;
    height: 100vh;
    grid-template-columns: 0.5fr 2fr;
    line-height: 1.5rem;
  }

  /* Show only on mobile  */

  .show-for-mobile {
    display: none;
  }

  /* Show only on large  */
  
  .show-for-large {
    display: contents;
  }

  /* Left side of the page - AboutMe.js */

  .left {
    color: var(--text-primary);
    background: var(--bg-primary);
    padding: 2rem;
  }
  .font-Monika {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    padding: 1rem 0;
    line-height: 2.2rem;
  }
  .picture-container {
    width: 15vw;
    padding: 1rem 0;
    margin: auto;
  }
  .picture {
    border-radius: 50%;
  }
  
  /* All icons - Even icons from pictures */
  .icons {
    color: var(--text-secondary);
  }
  .icons:hover {
    color: var(--text-third);
  }
  .icon-img {
    -webkit-filter: invert(60%) sepia(43%) saturate(195%) hue-rotate(142deg) brightness(94%) contrast(88%);
            filter: invert(60%) sepia(43%) saturate(195%) hue-rotate(142deg) brightness(94%) contrast(88%);
  }
  .icon-img:hover {
    -webkit-filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
            filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }
  .icons-grid {
    padding-top: 0.5rem;
    display: grid;
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-template-columns: repeat(auto-fit, minmax(1rem, 4.5rem));
    justify-content: center;
    justify-items: center;
    align-self: center;
  }
  .icons-grid-sm {
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 4rem);
    justify-content: center;
  }

  /* Right side of the page - MainNav.js, SideNav, CvNav and Content */

  .right {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto auto 1fr;
    grid-gap: 1rem 0;
    gap: 1rem 0;
    grid-template-areas:  "MainNav  Content"
                          "Cv       Content"
                          "SideNav  Content";
  }
  .mainav {
    grid-area: MainNav;
    background-color: var(--bg-secondary);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; 
    padding: 2rem 0;
  }
  .sidenav {
    grid-area: SideNav;
    background-color: var(--bg-secondary);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 2rem;
  }
    .cv {
    grid-area: Cv;
    border-radius: 5px !important;
    padding: 1rem;
    text-align: center;
    background-color: var(--bg-third);
    border: var(--bg-third) 1px solid;
    color: var(--text-4) !important;
    position: relative;
    overflow: hidden;
    
  }
  .cv:hover {
    background-color: var(--bg-primary);
    color: var(--text-third) !important;
    transition: all 500ms ease-Out;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .cv-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-primary);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .cv:hover .cv-circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -100px;
    left: -100px;
  }
  .cv:hover h3 {
    color: var(--text-third);
    z-index: 2;
    position: relative;
  }
  .content {
    grid-area: Content;
    padding: 3rem 2rem;
    background-color: var(--bg-primary);
  }

  /* Navbar selection */


  li .selected {
    padding: 1rem;
  }
  .is-active::before, .is-active:hover::before {
    background-color: var(--text-third);
    content: "";
    width: 0.5rem !important;
    z-index: -1;
    height: 60%;
    position: absolute;
    top: 20%;
    left: -0.5rem;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; 
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .selected:hover::before {
    background-color: var(--text-third);
    content: "";
    width: 0.5rem;
    z-index: -1;
    height: 60%;
    position: absolute;
    top: 20%;
    left: -0.5rem;
    border-radius: 5px;
  }
  .selected:hover {
    border-radius: 5px;
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
    transition: all 100ms ease-in-out;
  }
  .is-active {
    background-color: var(--bg-secondary);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
    transition: all 100ms ease-in-out;
  }

  /* Content editing */

  .img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  }
  .img-greece {
    width: 25vw;
    border-radius: 5px;
  }
  .img-udemy {
    width: 30vw;
    margin: auto;
  }
  a .pb:hover {
    -webkit-filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
            filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }
  .content-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  /* Button - from white to red */

  .button {
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    text-align: center;
    border: var(--bg-third) 1px solid;
    color: var(--text-third) !important;
    position: relative;
    overflow: hidden;
    transition: all 500ms ease-Out;
  }
  .button-flex {
    display: flex;
    justify-content: space-evenly;
  }
  .button:hover {
    transition: all 500ms ease-in-out;
    color: var(--text-4) !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border: none;
  }
  .circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-third);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .button:hover .circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }
  .button:hover span {
    color: var(--text-4);
    z-index: 2;
    position: relative;
  }

  /* Carousel flickity - using slider.js*/

  .carousel-container {
    padding-top: 1rem;
    width: 90%;
    margin: auto;
  }
  .carousel {
    width: 50%;
    height: 22vw;
    width: 40vw;
    padding: 1rem;
    background-color: var(--bg-primary);
  }
  .carousel-cell {
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .flickity-page-dots {
    bottom: 0 !important;
  }
  .carousel-square {
    height: 100%;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }

  /* Accordion */
  .accor {
    transition: 0.5s;
    padding-left: 1rem;
  }
  .accor-head {
    padding: 0 0.2rem 0.5rem 0.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .accor-head:hover {
    color: var(--text-third);
  }
  .accor-body {
    background: var(--bg-primary);
    max-height: 0;
    overflow: hidden;
    transition: 300ms ease-in-out;
  }
  .accor-img {
    width: 30vh;
  }
  .accor-img-rec {
    width: 40vh;
  }
  .active > .accor-body {
    padding: 0.2rem 20px;
    max-height: 600px;
  }
  .active > .accor-head {
    font-weight: bold !important;
    color: var(--text-third);
    border-bottom: 3px solid var(--bg-third);
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .container {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    display: grid;
    height: 100vh;
    grid-template-columns: 0.8fr 2fr;
    line-height: 1.5rem;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 769px) {
  .container {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    display: grid;
    height: 100vh;
    grid-template-columns: 0.9fr 2fr;
    line-height: 1.5rem;
  }
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.full-height {
  height: 100vh;
}
.text-pink {
  color: var(--text-third) !important;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, blockquote, pre,
a, .NavLink, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
	padding: 0;
	border: 0;
}
a:link, a:visited {
    text-decoration: none !important;
    color: inherit;
}
/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus, .NavLink:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active, .NavLink:active,
a:hover, .NavLink:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
.center {
    text-align: center;
    align-items: center;
}

h1, h2, h3 {
    font-weight: inherit;
}
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5rem;
}
h3 {
    font-size: 1.17rem;
}
h4 {
    font-size: 1rem;
}
h5 {
    font-size: 0.83rem;
}
h6 {
    font-size: 0.67rem;
}
p {
    font-size: 18px;
}
.bold {
    font-weight: bold !important;
}
.light {
    font-weight: lighter !important;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.justify {
    text-align: justify;
}

.NavLink {
    text-decoration: none;
    color: inherit;
    display: block;
}
.pb {
    padding-bottom: 1rem;
}
.pt {
    padding-top: 1rem;
}
.pt-sm {
    padding-top: 0.5rem;
}
.pl {
    padding-left: 1rem;
}
.pl-sm {
    padding-left: 0.5rem;
}

a:focus, .NavLink:focus {
    outline: none !important;
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    --text-primary: #0B4141;
    --text-secondary: #7DA2A9;
    --text-third: #FF6864;
    --text-4: #fbfbfb;
    --bg-primary: #fbfbfb;
    --bg-secondary: #7DA2A9;
    --bg-third: #FF6864;
    --transition-speed: 600ms;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    padding: 1.5rem 1.5rem;
    line-height: 1.5rem;
  }

  /* Transition through router in mobile  */

  .full-height {
    height: 100%;
  }
  .is-active {
    background-color: var(--bg-third) !important;
    border-radius: 5px;
    color: var(--text-4) !important;
  }
  
  /* Show only on large  */

  .show-for-large {
    display: none;
  }

  /* AboutMe.js */
  .picture-container {
    width: 50vw;
    margin: auto;
  }
  .picture {
    border-radius: 50%;
  }
  .font-Monika {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.1rem;
    padding: 1rem 0;
    line-height: auto;
  }
  /* All icons - Even icons from pictures */

  .icons {
    color: var(--text-secondary);
  }
  .icons:hover {
    color: var(--text-third);
  }
  .icon-img {
    -webkit-filter: invert(60%) sepia(43%) saturate(195%) hue-rotate(142deg) brightness(94%) contrast(88%);
            filter: invert(60%) sepia(43%) saturate(195%) hue-rotate(142deg) brightness(94%) contrast(88%);
  }
  .icon-img:hover {
    -webkit-filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
            filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }
  .icons-grid {
    display: grid;
    padding: 0.5rem;
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    grid-template-columns: repeat(auto-fit, 3rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
    justify-content: center;
    justify-items: center;
    align-self: center;
  }
  .icons-grid-sm {
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 4rem);
    justify-content: center;
  }

  /* CvNav - Download Resume */

  .cv {
    display: block;
    padding: 0.5rem 0;
    border-radius: 5px !important;
    text-align: center;
    background-color: var(--bg-third);
    border: var(--bg-third) 1px solid;
    color: var(--text-4) !important;
    position: relative;
    overflow: hidden;
  }
  .cv:hover {
    background-color: var(--bg-primary);
    color: var(--text-third) !important;
    transition: all 500ms ease-Out;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .cv-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-primary);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .cv:hover .cv-circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -100px;
    left: -100px;
  }
  .cv:hover h3 {
    color: var(--text-third);
    z-index: 2;
    position: relative;
  }

  /* Navbar selection */

  .smaller-text {
    padding: 0.5rem;
    font-size: 1.17rem !important;
    margin: 0.5rem 0;
  }
  h1.smaller-text{
    font-weight: bold !important;
    padding: 0;
    font-size: 1.5rem !important;
  }
  h3.smaller-text {
    font-size: 1rem !important;
  }
  .smaller-text-link {
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 1.17rem !important;
    margin: 0.5rem 0;
  }
  .smaller-text-color {
    padding: 0.5rem;
    font-size: 1.17rem !important;
    text-align: center;
    color: var(--text-secondary);
    font-weight: bold;
  }
  .smaller-text-ed {
    font-size: 1.17rem !important;
    padding: 0.5rem 0 0 0;
  }
  .mobile-navlink {
    background-color: var(--bg-secondary);
    border-radius: 5px;
    text-align: center;
  }

  /* Content editing */

  .img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  }
  .img-greece {
    width: 25vw;
    border-radius: 5px;
  }
  .img-udemy {
    width: 80vw;
    margin: auto;
  }
  a .pb:hover {
    -webkit-filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
            filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }

  /* Button - from white to red */

  .button {
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    text-align: center;
    border: var(--bg-third) 1px solid;
    color: var(--text-third) !important;
    position: relative;
    overflow: hidden;
    transition: all 500ms ease-Out;
  }
  .button-flex {
    display: grid;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .button:hover {
    transition: all 500ms ease-in-out;
    color: var(--text-4) !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border: none;
  }
  .circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-third);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .button:hover .circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }
  .button:hover span {
    color: var(--text-4);
    z-index: 2;
    position: relative;
  }

  /* Carousel flickity - using slider.js*/

  .carousel-container {
    width: 100%;
    margin: auto;
  }
  .carousel {
    width: 50%;
    height: 45vw;
    width: 80vw;
    padding: 0.5rem;
    background-color: var(--bg-primary);
  }
  .carousel-cell {
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
  .flickity-page-dots {
    bottom: 0 !important;
  }
  .carousel-square {
    height: 100%;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .flickity-button {
    width: 30px;
    height: 30px;
  }

  /* Accordion */
  .accor {
    transition: 0.5s;
  }
  .accor-head {
    padding: 0 0.2rem 0.5rem 0.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .accor-head:hover {
    color: var(--text-third);
  }
  .accor-body {
    background: var(--bg-primary);
    max-height: 0;
    overflow: hidden;
    transition: 300ms ease-in-out;
  }
  .accor-img {
    width: 25vh;
    margin: auto;
    padding-bottom: 0.5rem;
  }
  .accor-img-rec {
    width: 25vh;
    margin: auto;
  }
  .active > .accor-body {
    padding: 0.2rem 10px;
    max-height: 600px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .active > .accor-head {
    font-weight: bold !important;
    color: var(--text-third);
    border-bottom: 3px solid var(--bg-third);
  }
  .pl {
    padding-left: 0;
  }
  
}
@media only screen and (max-width: 768px) and (min-width: 425px) {
  .cv, .button {
    margin: auto;
    width: 70vw;
    padding: 1rem 0;
  }
  .mobile-navlink {
    margin: 0.5rem auto;
    width: 70vw;
  }
  .picture-container {
    width: 40vw;
  }
}

