@media only screen and (max-width: 768px) {
  :root {
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    --text-primary: #0B4141;
    --text-secondary: #7DA2A9;
    --text-third: #FF6864;
    --text-4: #fbfbfb;
    --bg-primary: #fbfbfb;
    --bg-secondary: #7DA2A9;
    --bg-third: #FF6864;
    --transition-speed: 600ms;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    padding: 1.5rem 1.5rem;
    line-height: 1.5rem;
  }

  /* Transition through router in mobile  */

  .full-height {
    height: 100%;
  }
  .is-active {
    background-color: var(--bg-third) !important;
    border-radius: 5px;
    color: var(--text-4) !important;
  }
  
  /* Show only on large  */

  .show-for-large {
    display: none;
  }

  /* AboutMe.js */
  .picture-container {
    width: 50vw;
    margin: auto;
  }
  .picture {
    border-radius: 50%;
  }
  .font-Monika {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.1rem;
    padding: 1rem 0;
    line-height: auto;
  }
  /* All icons - Even icons from pictures */

  .icons {
    color: var(--text-secondary);
  }
  .icons:hover {
    color: var(--text-third);
  }
  .icon-img {
    filter: invert(60%) sepia(43%) saturate(195%) hue-rotate(142deg) brightness(94%) contrast(88%);
  }
  .icon-img:hover {
    filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }
  .icons-grid {
    display: grid;
    padding: 0.5rem;
    grid-template-rows: min-content;
    grid-template-columns: repeat(auto-fit, 3rem);
    column-gap: 1rem;
    row-gap: 0.5rem;
    justify-content: center;
    justify-items: center;
    align-self: center;
  }
  .icons-grid-sm {
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 4rem);
    justify-content: center;
  }

  /* CvNav - Download Resume */

  .cv {
    display: block;
    padding: 0.5rem 0;
    border-radius: 5px !important;
    text-align: center;
    background-color: var(--bg-third);
    border: var(--bg-third) 1px solid;
    color: var(--text-4) !important;
    position: relative;
    overflow: hidden;
  }
  .cv:hover {
    background-color: var(--bg-primary);
    color: var(--text-third) !important;
    transition: all 500ms ease-Out;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .cv-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-primary);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .cv:hover .cv-circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -100px;
    left: -100px;
  }
  .cv:hover h3 {
    color: var(--text-third);
    z-index: 2;
    position: relative;
  }

  /* Navbar selection */

  .smaller-text {
    padding: 0.5rem;
    font-size: 1.17rem !important;
    margin: 0.5rem 0;
  }
  h1.smaller-text{
    font-weight: bold !important;
    padding: 0;
    font-size: 1.5rem !important;
  }
  h3.smaller-text {
    font-size: 1rem !important;
  }
  .smaller-text-link {
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 1.17rem !important;
    margin: 0.5rem 0;
  }
  .smaller-text-color {
    padding: 0.5rem;
    font-size: 1.17rem !important;
    text-align: center;
    color: var(--text-secondary);
    font-weight: bold;
  }
  .smaller-text-ed {
    font-size: 1.17rem !important;
    padding: 0.5rem 0 0 0;
  }
  .mobile-navlink {
    background-color: var(--bg-secondary);
    border-radius: 5px;
    text-align: center;
  }

  /* Content editing */

  .img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  }
  .img-greece {
    width: 25vw;
    border-radius: 5px;
  }
  .img-udemy {
    width: 80vw;
    margin: auto;
  }
  a .pb:hover {
    filter: invert(49%) sepia(8%) saturate(3786%) hue-rotate(314deg) brightness(113%) contrast(101%);
  }

  /* Button - from white to red */

  .button {
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    text-align: center;
    border: var(--bg-third) 1px solid;
    color: var(--text-third) !important;
    position: relative;
    overflow: hidden;
    transition: all 500ms ease-Out;
  }
  .button-flex {
    display: grid;
    row-gap: 0.5rem;
  }
  .button:hover {
    transition: all 500ms ease-in-out;
    color: var(--text-4) !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border: none;
  }
  .circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    border-radius: 50%;
    background: var(--bg-third);
    position: absolute;
    transition: all 600ms ease-Out;
    top: 50%;
    left: 50%;
  }
  .button:hover .circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }
  .button:hover span {
    color: var(--text-4);
    z-index: 2;
    position: relative;
  }

  /* Carousel flickity - using slider.js*/

  .carousel-container {
    width: 100%;
    margin: auto;
  }
  .carousel {
    width: 50%;
    height: 45vw;
    width: 80vw;
    padding: 0.5rem;
    background-color: var(--bg-primary);
  }
  .carousel-cell {
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
  .flickity-page-dots {
    bottom: 0 !important;
  }
  .carousel-square {
    height: 100%;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .flickity-button {
    width: 30px;
    height: 30px;
  }

  /* Accordion */
  .accor {
    transition: 0.5s;
  }
  .accor-head {
    padding: 0 0.2rem 0.5rem 0.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .accor-head:hover {
    color: var(--text-third);
  }
  .accor-body {
    background: var(--bg-primary);
    max-height: 0;
    overflow: hidden;
    transition: 300ms ease-in-out;
  }
  .accor-img {
    width: 25vh;
    margin: auto;
    padding-bottom: 0.5rem;
  }
  .accor-img-rec {
    width: 25vh;
    margin: auto;
  }
  .active > .accor-body {
    padding: 0.2rem 10px;
    max-height: 600px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .active > .accor-head {
    font-weight: bold !important;
    color: var(--text-third);
    border-bottom: 3px solid var(--bg-third);
  }
  .pl {
    padding-left: 0;
  }
  
}
@media only screen and (max-width: 768px) and (min-width: 425px) {
  .cv, .button {
    margin: auto;
    width: 70vw;
    padding: 1rem 0;
  }
  .mobile-navlink {
    margin: 0.5rem auto;
    width: 70vw;
  }
  .picture-container {
    width: 40vw;
  }
}
